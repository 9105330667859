<!--
* 创建人：罗兴
* 日 期：
* 描 述：添加学生
-->
<!--view 模板-->
<template>
  <div class="container">
    <div class="tool">
      <el-button type="primary" size="mini" @click="saveForm">保存</el-button>
    </div>
    <el-scrollbar style="height: 90%; width: 100%" class="myscrollbar">
      <div class="form">
        <el-form :model="forminfo" ref="formRef">
          <div class="bt">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="教工号" label-width="120px" prop="JGH">
                <el-input
                  v-model="forminfo.JGH"
                  autocomplete="off"
                  maxlength="20"
                  disabled
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="姓名" label-width="120px" prop="XM">
                <el-input
                  disabled
                  v-model="forminfo.XM"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="心理咨询证书"
                label-width="120px"
                prop="XLZXZS"
              >
                <el-select
                  v-model="forminfo.XLZXZS"
                  clearable
                  style="width: 100%"
                  size="small"
                >
                  <el-option label="有" :value="1"></el-option>
                  <el-option label="无" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="从事年限"
                label-width="120px"
                prop="CSXSGLNX"
              >
                <el-input
                  v-model="forminfo.CSXSGLNX"
                  autocomplete="off"
                  maxlength="20"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否专职" label-width="120px" prop="SFZZ">
                <el-select
                  v-model="forminfo.SFZZ"
                  clearable
                  style="width: 100%"
                  size="small"
                >
                  <el-option label="是" :value="1"></el-option>
                  <el-option label="否" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="毕业院校" label-width="120px" prop="BYYX">
                <el-input
                  v-model="forminfo.BYYX"
                  autocomplete="off"
                  maxlength="50"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="专业" label-width="120px" prop="ZY">
                <el-input
                  v-model="forminfo.ZY"
                  autocomplete="off"
                  maxlength="50"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="入职日期" label-width="120px" prop="RZRQ">
                <el-date-picker
                  v-model="forminfo.RZRQ"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  size="small"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" label-width="120px" prop="LXDH">
                <el-input
                  v-model="forminfo.LXDH"
                  autocomplete="off"
                  maxlength="50"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-scrollbar>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { getFDYPersonInfo, saveFDYInfo } from '../../api/jcxx'
export default {
  data() {
    return {
      forminfo: {
        ID: '',
        JGH: '',
        XM: '',
        XLZXZS: null,
        CSXSGLNX: '',
        SFZZ: null,
        BYYX: '',
        ZY: '',
        RZRQ: null,
        LXDH: '',
        bjlist: [],
      },
    }
  },
  created() {
    getFDYPersonInfo().then((res) => {
      if (res.code === 200) {
        this.forminfo.ID = res.data.id
        this.forminfo.JGH = res.data.jgh
        this.forminfo.XM = res.data.xm
        this.forminfo.XLZXZS = res.data.xlzxzs
        this.forminfo.CSXSGLNX = res.data.csxsglnx
        this.forminfo.SFZZ = res.data.sfzz
        this.forminfo.BYYX = res.data.byyx
        this.forminfo.ZY = res.data.zy
        this.forminfo.RZRQ = res.data.rzrq
        this.forminfo.LXDH = res.data.lxdh
      }
    })
  },
  methods: {
    // 保存信息
    saveForm() {
      saveFDYInfo(this.forminfo).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.dialogEditFormVisible = false
          this.getDataPageList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
}
.tool {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  background: white;
  min-width: 600px;
}
.form {
  width: 1240px;
  margin: 0 auto;
  border: 1px solid #e2e4e5;
  padding: 16px;
  border-radius: 6px;
  background: white;
  min-height: 20px;
}
.bt {
  background-color: #eeeeee;
  padding: 5px;
  font-weight: 700;
}
>>> .el-upload {
  /* border: 1px dashed #d9d9d9; */
  margin: 20px 0 0 120px;
  border: 1px solid #8c939d !important;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 126px;
  height: 180px;
  line-height: 180px;
  text-align: center;
}
.avatar {
  width: 126px;
  height: 180px;
  display: block;
}
</style>
